import React, { Component } from 'react';
import Cookies from 'universal-cookie';

class Sanitize {

	static capitalizeFirstLetter(str) {
		if (str.length < 1) return '';
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	static integerKeyPress(e) {
		if (e.which != 8 && (e.which < 48 || e.which > 57)) {
			//showAdvice(this, "Integer values only");
			e.preventDefault();
			return false;
		}
	}

	static integerIndMobile(e) {
		let len = e.target.value.length;
		if (len >= 10) {
			e.preventDefault();
			return false;
		}

		if (e.which != 8 && (e.which < 48 || e.which > 57)) {
			//showAdvice(this, "Integer values only");
			e.preventDefault();
			return false;
		}
	}
	
	static integerIndPincode(e) {
		let len = e.target.value.length;
		if (len >= 6) {
			e.preventDefault();
			return false;
		}

		if (e.which != 8 && (e.which < 48 || e.which > 57)) {
			//showAdvice(this, "Integer values only");
			e.preventDefault();
			return false;
		}
	}

	static decimalKeyPress(e) {
		if (e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
			//showAdvice(this, "Decimal numbers only");
			e.preventDefault();
			return false;
		}
		if (e.which == 46 && e.target.value.indexOf(".") != -1) {
			// showAdvice(this, "Only one period allowed in decimal numbers");
			e.preventDefault();
			return false;   // only one decimal allowed
		}
	}

	static lettersOnly(e) {
		var inputValue = e.which;
		// allow letters and whitespaces only.
		if (e.which != 8 && !(inputValue >= 65 && inputValue <= 122) && (inputValue != 32 && inputValue != 0)) {
			e.preventDefault();
			return (false);
		}
	}

	static dmy(d) {
		if (d == null || d == '') return '';
		var today = new Date(d);
		var date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
		return date;
	}

	static dmyDash(d) {
		if (d == null || d == '' || d == '0000-00-00') return '';
		var today = new Date(d);
		var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
		return date;
	}

	static urlReplace(v) {
		return (v.replace(new RegExp(' ', 'g'), '-').toLowerCase());
	}

	static dateTomonth(dob) {

		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		var emptydob = '';
		if (dob != '0000-00-00' && dob != null) {
			var ed = new Date(dob);
			var edate = ed.setDate(ed.getDate());

			var d = new Date(edate),
				month = '' + (d.getMonth() + 1),
				day = '' + (d.getDate()),
				year = d.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			var mon = monthNames[d.getMonth()];
			var monChange = mon.substring(0, 3);
			// var splitmonth = mon.substr(0, 3);
			//return [da, mon, yea].join(' - ');
			//return [monChange, year].join(' , ');
			return monChange + ' ' + day + ', ' + year;
		}
		else {

			return emptydob;
		}
	}

	static getToday() {
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth() + 1; //January is 0!
		var yyyy = today.getFullYear();

		if (dd < 10) {
			dd = '0' + dd
		}

		if (mm < 10) {
			mm = '0' + mm
		}

		today = dd + '/' + mm + '/' + yyyy;
		return today;
	}

	static timeConvert(time) {
		// Check correct time format and split into components
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) { // If time format correct
			time = time.slice(1);  // Remove full string match value
			time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(''); // return adjusted time or original string
	}

	static getIndex(value, arr, prop) {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i][prop] === value) {
				return i;
			}
		}
		return -1; //to handle the case where the value doesn't exist
	}

	static inArray(value, arr) {

		var found = arr.find(function (element) {
			return element.id == value;
		});

		return (found) ? true : false;
	}

	/** Group by data using multiple column **/
	static groupByMultiple(array, f) {
		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		});
	}

	static convertNumberToWords(amount) {
		var words = new Array();
		words[0] = '';
		words[1] = 'One';
		words[2] = 'Two';
		words[3] = 'Three';
		words[4] = 'Four';
		words[5] = 'Five';
		words[6] = 'Six';
		words[7] = 'Seven';
		words[8] = 'Eight';
		words[9] = 'Nine';
		words[10] = 'Ten';
		words[11] = 'Eleven';
		words[12] = 'Twelve';
		words[13] = 'Thirteen';
		words[14] = 'Fourteen';
		words[15] = 'Fifteen';
		words[16] = 'Sixteen';
		words[17] = 'Seventeen';
		words[18] = 'Eighteen';
		words[19] = 'Nineteen';
		words[20] = 'Twenty';
		words[30] = 'Thirty';
		words[40] = 'Forty';
		words[50] = 'Fifty';
		words[60] = 'Sixty';
		words[70] = 'Seventy';
		words[80] = 'Eighty';
		words[90] = 'Ninety';
		amount = amount.toString();
		var atemp = amount.split(".");
		var number = atemp[0].split(",").join("");
		var n_length = number.length;
		var words_string = "";
		if (n_length <= 9) {
			var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
			var received_n_array = new Array();
			for (var i = 0; i < n_length; i++) {
				received_n_array[i] = number.substr(i, 1);
			}
			for (var i = 9 - n_length, j = 0; i < 9; i++ , j++) {
				n_array[i] = received_n_array[j];
			}
			for (var i = 0, j = 1; i < 9; i++ , j++) {
				if (i == 0 || i == 2 || i == 4 || i == 7) {
					if (n_array[i] == 1) {
						n_array[j] = 10 + parseInt(n_array[j]);
						n_array[i] = 0;
					}
				}
			}
			var value = "";
			for (var i = 0; i < 9; i++) {
				if (i == 0 || i == 2 || i == 4 || i == 7) {
					value = n_array[i] * 10;
				} else {
					value = n_array[i];
				}
				if (value != 0) {
					words_string += words[value] + " ";
				}
				if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Crores ";
				}
				if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Lakhs ";
				}
				if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Thousand ";
				}
				if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
					words_string += "Hundred and ";
				} else if (i == 6 && value != 0) {
					words_string += "Hundred ";
				}
			}
			words_string = words_string.split("  ").join(" ");
		}
		return words_string;
	}

	static objectifyForm(formArray) {//serialize data function

		var returnArray = {};
		for (var i = 0; i < formArray.length; i++) {
			returnArray[formArray[i]['name']] = formArray[i]['value'];
		}
		return returnArray;
	}

	static onLogout() {

		var cookie = new Cookies();
		cookie.set('cb_ecom_user_logged', 'no');
		localStorage.removeItem('cartData');
		localStorage.removeItem('loginUser');
		window.location.href = '/home';
	}
}

export default Sanitize;