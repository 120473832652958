import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Card, Collapse  } from 'react-bootstrap';

import API from '../../../utils/API';
import { capitalizeFirst } from '../../../utils';
import PsContext from '../../../context/PsContext';

const CategoryFilter=(props)=>{
	
	const context = useContext(PsContext);

	const isMobileDevice=()=>{
		return !(document.body.offsetWidth < 700);
	};

	const [loader, setLoader] = useState(true);
	const [categories, setCategories] = useState([]);
	const [open, setOpen] = useState(isMobileDevice());
	
	useEffect(()=>{
		
		loadCategories();
	},[]);
	
	const loadCategories=()=>{
		API.get('v1/site/category/menu').then(res => {
			if (res['data'].status == '1') {
			  setCategories( res['data'].data );
			  setLoader(false);
			}
		  });
	};
	
	return(
		<Card className="card-style1 mb-3" >
			<div className="card-headers pt-3 pb-3 pl-3 pr-3 border-bottom" onClick={e=>setOpen(!open)}>
				<a className="h6 bg-white" >
					<b> CATEGORIES</b>
				</a>
				<span style={{float: 'right'}}>
					{open ? <i className="lni lni-chevron-up"></i>
					 : <i className="lni lni-chevron-down"></i>}
				</span>
			</div>
			{/*<span className="input-icon">
				  <i className="material-icons">search</i>
				  <input type="search" className="form-control" id="searchBrand" placeholder="Search..." />
			</span>*/}
			<Collapse in={open} >
				<Card.Body>
					<div id="brandList" className="mt-3 overflow-auto scrollbar-width-thin bg-lights px-1" style={{ maxHeight: '400px' }} >
						{categories && categories.length>0 && (categories.map((item,i)=>{
						return item.categoryname && item.categoryname.length>3 ? <NavLink to={{
							pathname: `/category/${item.slug}`,
						}}
							className="dropdown-item" activeClassName="active" style={{ textTransform: 'capitalize' }} >
							{capitalizeFirst(item.categoryname)}
						</NavLink> : null;
						})
						)}
					</div>
					</Card.Body>
			</Collapse>
		</Card>
	);
};
export default CategoryFilter;