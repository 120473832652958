import { lazy } from 'react';

import NewSearch from './pages/HeaderSearch/newSearch';
import SingleProductView from './pages/Products/singleProductView';
import NewCategoryView from './pages/Category/newCategoryView';
import NewCart from './pages/newCart/newCart';
import OrderStatus from './pages/userAccount/orderStatus';
import Offers from './pages/offers';
import MyOrders from './pages/userAccount/myOrders';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Products = lazy(() => import('./pages/Products'));
const Category = lazy(() => import('./pages/Category'));
const ProductView = lazy(() => import('./pages/Products/Filters/ProductView'));
const CartList = lazy(() => import('./pages/Cart'));
const Shipping = lazy(() => import('./pages/Cart/Shipping'));
const Payment = lazy(() => import('./pages/Cart/Payment'));
const Review = lazy(() => import('./pages/Cart/Review'));
const PrivacyPolicy = lazy(() => import('./pages/About/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./pages/About/RefundPolicy'));
const ShippingPolicy = lazy(() => import('./pages/About/ShippingPolicy'));
const Disclaimer = lazy(() => import('./pages/About/Disclaimer'));
const Settings = lazy(() => import('./pages/Settings'));
const Orders = lazy(() => import('./pages/Settings/Orders'));
const Address = lazy(() => import('./pages/Settings/Address'));
const Response = lazy(() => import('./pages/Response'));
const NotFound = lazy(() => import('./pages/NotFound'));
const HeaderSearch = lazy(() => import('./pages/HeaderSearch'));


const pageList = [
  {
    name: 'Home',
    path: '/',
    component: Home
  },
  {
    name: 'Home',
    path: '/home',
    component: Home
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Settings
  },
  {
    name: 'Orders',
    path: '/orders',
    component: Orders
  },
  {
    name: 'Address',
    path: '/address',
    component: Address
  },
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    name: 'About',
    path: '/about',
    component: About
  },
  {
    name: 'Contact',
    path: '/contact',
    component: Contact
  },
  {
    name: 'Products',
    path: '/products',
    component: Products
  },
   {
    name: 'Category',
    path: '/category/:name?',
    component: NewCategoryView
  },
  {
    name: 'Category',
    path: '/category/:id/:name?',
    component: Category
  },
  {
    name: 'ProductView',
    path: '/product/:id?',
    component: ProductView
  },
   {
    name: 'ProductView',
    path: '/:slug/p/:id?',
    component: SingleProductView
  },
  {
    name: 'HeaderSearch',
    path: '/search/:keyword?',
    component: NewSearch,
    //component: HeaderSearch,
  },
  {
    name: 'CartList',
    path: '/cart',
    component: NewCart
  },
  {
    name: 'Shipping',
    path: '/shipping',
    component: Shipping
  },
 /*  {
    name: 'Payment',
    path: '/payment/:status?',
    component: Payment
  }, */
  {
    name: 'Review',
    path: '/review',
    component: Review
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    component: PrivacyPolicy
  },
  {
    name: 'ShippingPolicy',
    path: '/shipping-policy',
    component: ShippingPolicy
  },
  {
    name: 'RefundPolicy',
    path: '/refund-policy',
    component: RefundPolicy
  },
  {
    name: 'Disclaimer',
    path: '/disclaimer',
    component: Disclaimer
  },
  {
    name: 'Response',
    path: '/response',
    component: Response
  },
  {
    name: 'Order Status',
    path: '/user/order-status/:uid',
    component: OrderStatus
  },
  {
    name: 'Order Status',
    path: '/user/my-orders',
    component: MyOrders
  },
  {
    name: 'Offers',
    path: '/offers',
    component: Offers
  },
  {
    name: 'Not Found',
    path: '/404',
    component: NotFound
  },
  {
    name: 'Not Found',
    path: '*',
    component: NotFound
  },

];
export default pageList;