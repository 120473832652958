import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter , Switch, Route, Link, HashRouter, IndexRoute, Redirect } from 'react-router-dom';

import { Slider} from '../views/elements';
import {Api} from '../utils/Variables';
import API from '../utils/API';
import Cookies from 'universal-cookie';
import routes from '../views';
import {setCookie, setlsItem} from '../utils';

import toast, { Toaster } from 'react-hot-toast';

import { TopHeader, Header, TopMenu, Footer } from '../views/components';
import PsContext from '../context/PsContext';

class SiteLayout extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: true,
		}
	}
	
	componentDidMount(){
		this.loadSiteinfo();
	}
	
	loadSiteinfo(){
		try{
			
			API.get('site/').then(res=>{
				if(res['data'].status=='1')
				{
					this.context.updateCompanyInfo(res['data'].data);
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			console.log(error);
		}
	}
	
	
	render(){
				
		return(
			<div>
			
			<Toaster
			  position="top-center"
			  reverseOrder={false}
			  gutter={8}
			  containerClassName=""
			  containerStyle={{zIndex: '99999',}}
			  toastOptions={{
				// Define default options
				className: '',
				duration: 5000,
				style: {
				  background: '#fff',
				  color: '#363636',
				},
				// Default options for specific types
				success: {
				  duration: 3000,
				  theme: {
					primary: 'green',
					secondary: 'black',
				  },
				},
			  }}
			/>

			{/*<Header routes={routes} />*/}
			
			<TopHeader />
				
				<Header />
				
				<TopMenu />
				
					<div className="app-page-content">
						<Switch>
						{routes.map((page,key)=>(
							<Route exact path={page.path}  component={page.component} key={key} />
						))}
						
						</Switch>
					</div>
					
			<Footer />
				
			</div>
		);
	}
	
}
export default SiteLayout;