import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';
import { getlsItem } from '../../../utils';

import CartProduct from './cartProduct';

const CartModal = (props) => {
	
	const context = useContext(PsContext);
	const [cartData, setData] = useState([]);
	
	/**
	* to set cart data
	* get from localstorage
	*/
	const setCartData=()=>{
		const data = getlsItem('cartData') ? JSON.parse(getlsItem('cartData')) : [];
		setData(data);
	};
	
	useEffect(()=>{
		
		setCartData();
	},[]);
	
	/**
	* to list cart data 
	*/
	const listCartData=()=>{
		
		var rv = [];
		if(cartData && cartData.length>0){			
			cartData.map((item,i)=>{
				rv.push(<li className="list-group-item px-0">
					{<CartProduct product={item} onCancel={props.onCancel} />}
				</li>);
				//setTotal((cartTotal + parseFloat(item.quantity * item.salesrate)));
			});
		}
		return rv;
	};
	
	
	const totalAmount=()=>{
		var total = 0;
		cartData.map((item,i)=>{				
			total += parseFloat(item.quantity * item.salesrate);
		});
		return total;
	};
	
	return(
		 <Modal show className="modal-content-right modal-cart" onHide={props.onCancel} >
			<Modal.Header className="border-bottom" >
				 <h5 className="modal-title" id="cartModalLabel">
				 {`You have ${context.state.cartCount} items in your cart`}
				</h5>
				  <button className="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal" onClick={props.onCancel} >
					<i className="material-icons">close</i>
				  </button>
			</Modal.Header>
			<Modal.Body>
				<ul className="list-group list-group-flush">
					{listCartData()}
				</ul>
			</Modal.Body>
			<Modal.Footer>
				 <div className="mr-auto">
					<em>Subtotal</em>
					<h5 className="mb-0 text-dark font-weight-bold font-condensed">Rs. {totalAmount()}</h5>
				  </div>
				  <Link  to="/cart" className="btn btn-faded-success" onClick={props.onCancel} >View Cart</Link>
				  <Link  to="/cart"  className="btn btn-success" onClick={props.onCancel} >Checkout</Link>
			</Modal.Footer>
		 </Modal>
	);
}

export default CartModal;