import React from 'react';
import ReactDOM from 'react-dom';
import './assets/lineicons/LineIcons.min.css';

import './assets/css/style.min.css';
import './assets/silkafont/stylesheet.css';
import './index.css';

import App from './App';

import './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

