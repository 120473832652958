import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import MobileMenuModal from './mobileMenuModal';
import LoginForm from '../../pages/userAccount/loginForm';
const styles = {
	navLink: {
		position: 'absolute',
		top: '10px',
	}
}

const MobileMenuLink = () => {
	
	const [showModal, setModal] = useState(false);

	const [show, setLogin] = useState(false);
	
	/**
	* set the modal open 
	* toggle of modal
	*/
	const openModal = ()=> {
		setModal( !showModal );
	};
	
	return(
		<>
			 <a className="nav-link nav-icon d-lg-none" style={styles.navLink} onClick={openModal} >
				<i className="material-icons">menu</i>
			</a>
			
			{showModal && (<MobileMenuModal onCancel={openModal} 
				afterFinish={()=> {
					setLogin(true);
					openModal();
				}} 

				afterLogout={()=> {
					setModal(false);
				}}
				
			/>)}

			<Modal show={show} onHide={()=> setLogin(false) } backdrop="static" >
				<Modal.Header closeButton >
					Sign In / Sign Up
				</Modal.Header>
				<Modal.Body>
					<LoginForm afterFinish={()=> setLogin(false) } />
					
					<br /><br />
					
				</Modal.Body>
			  </Modal>
		</>
	);
}

export default MobileMenuLink;