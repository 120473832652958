import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Card, Collapse  } from 'react-bootstrap';

import API from '../../../utils/API';
import { capitalizeFirst } from '../../../utils';
import PsContext from '../../../context/PsContext';

const CategoryOffCanvas=(props)=>{
	
	const context = useContext(PsContext);

	const isMobileDevice=()=>{
		return !(document.body.offsetWidth < 700);
	};

	const [loader, setLoader] = useState(true);
	const [categories, setCategories] = useState([]);
	const [open, setOpen] = useState(isMobileDevice());
	
	useEffect(()=>{
		
		loadCategories();
	},[]);
	
	const loadCategories=()=>{
		API.get('v1/site/category/menu').then(res => {
			if (res['data'].status == '1') {
			  setCategories( res['data'].data );
			  setLoader(false);
			}
		  });
	};
	
	return(
		
		<div id="brandList" className="overflow-auto scrollbar-width-thin bg-lights px-1" style={{height: '500px'}} >
			{categories && categories.length>0 && (categories.map((item,i)=>{
			return item.categoryname && item.categoryname.length>3 ? <div className='ps-cat-link'>
				<NavLink to={{
				pathname: `/category/${item.slug}`,
			}}
				className="dropdown-item" activeClassName="active" style={{ textTransform: 'capitalize' }} 
				onClick={e => props.linkClick() }
				>
				{capitalizeFirst(item.categoryname)}
			</NavLink> 
			</div>: null;
			})
			)}
		</div>
					
	);
};
export default CategoryOffCanvas;