import React, { useState, useEffect, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';
import API from '../../../utils/API';
import { upperCase } from '../../../utils';
import { toast } from 'react-hot-toast';
import FeaturedProduct from '../../elements/Products/Featured';

import ProductLoader from '../Home/productLoader';
import CategoryFilter from './categoryFilter';

import NO_PRODUCT_IMG from '../../../assets/img/no_products.png';
import CategoryOffCanvas from './categoryOffCanvas';

const NewCategoryView=(props)=>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState(true);
	const [products, setProducts] = useState([]);
	const [startIndex, setStartIndex] = useState(1);
	const [limit, setLimit] = useState(20);
	const [activeClass, setActiveClass] = useState('normal');
	const [showOffcanvas, setShowOffcanvas] = useState(false);
	
	const slug = props.match.params.name;

	useEffect(()=>{
		window.addEventListener('scroll', () => {
			let acl = 'top';
			if(window.scrollY === 0){
				acl = 'normal';
			}
			setActiveClass(acl);
		});
	},[]);
	
	useEffect(()=>{
		
		setProducts([]);
		loadProducts();
		bodyToTop();
	},[slug]);
	
	const bodyToTop=()=>{
		$('html, body').animate({ scrollTop: 0 }, 'slow');
	};
		
		
	const loadProducts=()=>{
		setLoader(true);
		API.get(`v1/site/item/category/${slug}/${startIndex}/${limit}`).then(res => {
			if (res['data'].status == '1') {
				var d = products;
				setProducts(res['data'].data);
				setLoader(false);
			}
			else {
				setLoader(false);
			}
		});
	};
	
	const addToCart=(id)=>{
		var d = products;
		var index = d.findIndex(function (obj) {
			return (obj.id == id);
		})
		var dat = d[index];
		dat['quantity'] = 1;
		context.addToCart(dat);
		toast.success('Successfully added to cart!.');
	};
	
	return(
	<>
		 <div className="breadcrumb-container ">
			<div className="container-fluid">
			  <nav aria-label="breadcrumb">
				<ol className="breadcrumb">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				   <li className="breadcrumb-item">
					<a>Category</a>
				  </li>
				  <li className="breadcrumb-item active" aria-current="page">
					<a>{upperCase(slug)}</a>
				  </li>
				</ol>
			  </nav>
			</div>
		  </div>

			<div className={`md_hide ps-category-top-filter-container ps-category-fixed-${activeClass} `} >
				<Card className='bg_theme' >
					<Card.Body style={{padding: '0px'}} className='bg_theme'>
						
					<Row>
						<Col xs={6} className="text-center border-end" style={{borderRight:'1px solid #9a9a9a'}}>
							<Button variant="theme" block size="lg" >
								Sort
							</Button>
						</Col>
						<Col xs={6} className="text-center">
							<Button variant="theme" block  size="lg" onClick={e => setShowOffcanvas(!showOffcanvas)} >
								Category
							</Button>
						</Col>
					</Row>
					
					</Card.Body>
				</Card>
			</div>
		  
		  <div className="container-fluid my-3">
						
			<Row className="gutters-3" >
				<Col md={3} className="sm_hide">
					<CategoryFilter />
				</Col>
				
				<Col md={9} >
					
					{loader && (<ProductLoader size={8} />)}
					
					<Row>
						{products && products.length>0 && (products.map((item, i) => {
							return <Col xs={6} sm={6} md={3} className='ps_category_list' >
								<FeaturedProduct
									title={item.itemname}
									image={item.image}
									id={item.id}
									height="320px"
									item={item}
									amount={item.salesrate} 
									addToCart={()=>{addToCart(item.id);}}
								/>
							</Col>;
						}))}
					</Row>
					
					{!loader && products && products.length<1 && (<center>
						<img src={NO_PRODUCT_IMG} />
						<h4 className="mt-5" >SORRY! WE COULDN'T FIND ANY PRODUCTS</h4>
					</center>)}
					
				</Col>
				
			</Row>

			<Modal show={showOffcanvas} className='modal-bottom' onHide={e => setShowOffcanvas(false)} >
				<Modal.Header closeButton >
					Category
				</Modal.Header>
				<Modal.Body>
						<CategoryOffCanvas  linkClick={e => setShowOffcanvas(false)} />
				</Modal.Body>
			</Modal>
			
		  </div>
	</>
	);
};
export default withRouter(NewCategoryView);