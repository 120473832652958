import React, { useState, useContext, useEffect } from 'react';

import PsContext from '../../../context/PsContext';

import CartModal from './cartModal'

const styles = {
	cartInRight: {
		position: 'absolute',
		top: '13px',
		right: '-13px',
	}
};

const CartLink = () => {
	
	const context = useContext(PsContext);
	const [showModal, setModal] = useState(false);
	
	/**
	* to handle cart button click
	* if the count of cart items in localstorage is greater than zero then showModal is set true
	*/
	const cartClick =()=>{
		
		if(context.state.cartCount > 0)
		{
			setModal( !showModal );
			context.showCart( !context.state.showCartModal );
			
		}
	};
	
	return(
		<>
			{/* desktop view cart icon */}
			 <a className="btn nav-icon has-badge d-none d-sm-block ps_cart_web" onClick={cartClick} >
				  <i className="material-icons">shopping_cart</i>
				  <span className="badge badge-pill badge-danger">{context.state.cartCount}</span>
				  Cart
			 </a>
			 
			 {/* mobile view cart icon */}
			 <a className="btn nav-icon has-badge d-md-none d-lg-none" onClick={cartClick} style={styles.cartInRight} >
				  <i className="material-icons">shopping_cart</i>
				  <span className="badge badge-pill badge-danger">{context.state.cartCount}</span>
			 </a>
			 
			 {(context.state.showCartModal) && (<CartModal onCancel={cartClick} />)}
		</>
	);
}

export default CartLink;