import React, { useState, useContext, useEffect } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';

const UserMenuNew = (props) => {
	
	const context = useContext(PsContext);
	const [show, showModal] = useState(false);
	
	return(
		 <>
			<a href="javascript:;" className="btn nav-icon has-badge " onClick={()=> showModal(true) } style={props.style} >
				<i className="material-icons mr-1">person</i>
			</a>
			
			{show && ( <Modal show className="modal-content-right modal-cart" onHide={()=> showModal(false) } >
				<Modal.Header className="border-bottom" >
					 <h5 className="modal-title" id="cartModalLabel">
					 MENU
					</h5>
					  <button className="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal" onClick={()=> showModal(false) } >
						<i className="material-icons">close</i>
					  </button>
				</Modal.Header>
				<Modal.Body>
					<ul className="side-user-menu">
						<li >
							<Link to="/user/my-orders" className="list-group-item" >My Orders</Link>
						</li>
						<li >
							<Link to="/cart" className="list-group-item" >Go to Cart</Link>
						</li>
						<li >
							<a href="javascript:;" className="list-group-item" onClick={()=> context.userLogout() } >Signout</a>
						</li>
					</ul>
				</Modal.Body>
				<Modal.Footer>
					
				</Modal.Footer>
			</Modal> )}
			 
		 </>
	);
}

export default UserMenuNew;