import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Button, InputGroup  } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import API from '../../../utils/API';
import { integerIndMobile } from '../../../utils';

import ForgotPassword from './forgotPassword';

const LoginForm = (props) =>{
	
	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(null);
	const [mobile, setMobile] = useState('');
	
	const handleSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoading(true);
		
		API.post('v1/site/user/check', $("#frm_userLogin").serialize()).then(res => {
			if (res['data'].status == '1') {
				
				setType(res['data'].type);
				setLoading(false);
				/*toast.success('Login Successfully...');		
				context.userLogin(res['data'].userdata);
				if(props.afterFinish)
					props.afterFinish();*/
			}
			else {
				toast.error(res['data'].message,{
					 style: {
					  marginTop: '5%',
					  borderRadius: '1px',
					  background: '#333',
					  color: '#fff',
					},
				});
				setLoading(false);
			}
		});
	};
	
	
	const handleSignUp=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoading(true);
		
		API.post('v1/site/user/checkotp', $("#frm_new_User").serialize()).then(res => {
			if (res['data'].status == '1') {

				toast.success('Login Successfully...');		
				context.userLogin(res['data'].userdata);
				if(props.afterFinish)
					props.afterFinish();
			}
			else {
				toast.error(res['data'].message,{
					 style: {
					  marginTop: '5%',
					  borderRadius: '1px',
					  background: '#333',
					  color: '#fff',
					},
				});
				setLoading(false);
			}
		});
	};
	
	const handleLoginSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoading(true);
		
		API.post('v1/site/userlogin/', $("#frm_usr_ext_login").serialize()).then(res => {
			if (res['data'].status == '1') {

				toast.success('Login Successfully...');		
				context.userLogin(res['data'].userdata);
				if(props.afterFinish)
					props.afterFinish();
			}
			else {
				toast.error(res['data'].message,{
					 style: {
					  marginTop: '5%',
					  borderRadius: '1px',
					  background: '#333',
					  color: '#fff',
					},
				});
				setLoading(false);
			}
		});
	};
	
	const termsLink=()=>{
		return <div style={{ fontSize: '12px' }} >
			By continuing, you agree to our <Link to="/privacy-policy" target="_blank" >Terms of Use and our Privacy Policy</Link>.
		</div>;
	};
	
	const forgotPasswordLink=()=>{
		return <Col md={12} className="mt-3" >
			<a href="javascript:;" style={{fontSize :'14px'}} onClick={ e=> setType('forgot') }  >
				Forgot Password
			</a>	
		</Col>;
	}
	
	const mobileInput=()=>{
		return <Form
			action=""
			method="post"
			id="frm_userLogin"
			className="frm_login"
			noValidate 
			validated={validated} 
			onSubmit={handleSubmit}
		>
			<Row>
				<Col md={12} >
					<label>Mobile </label>
					<InputGroup >
					 <InputGroup.Text >+91</InputGroup.Text>
					<Form.Control
						type="number"
						name="mobile"
						placeholder="Mobile Number"
						required
						value={mobile}
						onKeyPress={integerIndMobile}
						onChange={(e)=> setMobile(e.target.value) }
						autoFocus={true}
					/>
					</InputGroup >
				</Col>
			</Row>
			
			
			<Row className="mt-4" >
				<Col md={12} >
					{termsLink()}
				</Col>
				
			</Row>
			
			<Row className="mt-4" >
				<Col md={12} >
					<Button
						type="submit"
						block
						disabled={loading}
					>
					{loading ? 'Validating..' : 'Continue'}
					</Button>
				</Col>
				{forgotPasswordLink()}
			</Row>
			
		</Form>;
	};
	
	const signUpForm=()=>{
		return <Form
			action=""
			method="post"
			id="frm_new_User"
			className="frm_login"
			noValidate 
			validated={validated} 
			onSubmit={handleSignUp}
		>
			<Row>
				<Col md={12} >
					<label>Mobile </label>
					<InputGroup >
					 <InputGroup.Text >+91</InputGroup.Text>
					<Form.Control
						type="number"
						name="mobile"
						placeholder="Mobile Number"
						required
						value={mobile}
					/>
					</InputGroup>
				</Col>
			</Row>
			
			<Row>
				<Col md={12} >
					<label>OTP </label>
					<Form.Control
						type="text"
						name="otp"
						placeholder="Enter OTP"
						required
						autoFocus={true}
					/>
				</Col>
			</Row>
			
			<Row>
				<Col md={12} >
					<label>Password </label>
					<Form.Control
						type="password"
						name="password"
						placeholder="Your Password"
						required
					/>
				</Col>
			</Row>
			
			
			<Row className="mt-4" >
				<Col md={12} >
					{termsLink()}
				</Col>
			</Row>
			
			<Row className="mt-4" >
				<Col md={12} >
					<Button
						type="submit"
						block
						disabled={loading}
					>
					{loading ? 'Validating..' : 'Continue'}
					</Button>
				</Col>
				{forgotPasswordLink()}
			</Row>
			
		</Form>;
	};
	
	
	const loginForm=()=>{
		return <Form
			action=""
			method="post"
			id="frm_usr_ext_login"
			className="frm_login"
			noValidate 
			validated={validated} 
			onSubmit={handleLoginSubmit}
		>
			<Row>
				<Col md={12} >
					<label>Mobile </label>
					<InputGroup >
					 <InputGroup.Text >+91</InputGroup.Text>
					<Form.Control
						type="number"
						name="mobile"
						placeholder="Mobile Number"
						required
						value={mobile}
					/>
					</InputGroup>
				</Col>
			</Row>
						
			<Row>
				<Col md={12} >
					<label>Password </label>
					<Form.Control
						type="password"
						name="password"
						placeholder="Your Password"
						required
						autoFocus={true}
					/>
				</Col>
			</Row>
			
			
			<Row className="mt-4" >
				<Col md={12} >
					{termsLink()}
				</Col>
			</Row>
			
			<Row className="mt-4" >
				<Col md={12} >
					<Button
						type="submit"
						block
						disabled={loading}
					>
					{loading ? 'Validating..' : 'Continue'}
					</Button>
				</Col>
				{forgotPasswordLink()}
			</Row>
			
		</Form>;
	};
	
	return(
	<>
		{type === null && (mobileInput())}
		
		{type === 'new' && (signUpForm())}
		
		{type === 'customer' && (loginForm())}
		
		{type === 'forgot' && (<ForgotPassword mobile={mobile} afterFinish={props.afterFinish} />)}
	
	</>
	);
};
export default LoginForm;