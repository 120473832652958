import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import { Api } from '../../../utils/Variables';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { setlsItem, getlsItem } from '../../../utils';
import PsContext from '../../../context/PsContext';

class Cart extends Component {
	
	static contextType = PsContext;
	
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	onRemoveAll(e) {
		if (window.confirm('Do you want to remove all items?')) {
			this.context.updateCart([]);
			toast.success('Successfully removed all items to cart!.');
			this.forceUpdate();
		}
	}

	onRemoveItem(id, e) {
		if (window.confirm('Do you want to remove?')) {
			var d = JSON.parse(getlsItem('cartData'));
			var index = d.findIndex(function (obj) {
				return (obj.id == id);
			})
			d.splice(index, 1);
			this.context.updateCart(d);
			toast.success('Card Item removed Successfully!.');
			this.forceUpdate();
		}
	}

	onAddQuantity(id, e) {
		var d = JSON.parse(getlsItem('cartData'));
		var index = d.findIndex(function (obj) {
			return (obj.id == id);
		})
		d[index].quantity += 1;
		this.context.updateCart(d);
		this.forceUpdate();
	}

	onMinusQuantity(id, e) {
		var d = JSON.parse(getlsItem('cartData'));
		var index = d.findIndex(function (obj) {
			return (obj.id == id);
		})
		if (d[index].quantity > 1) {
			d[index].quantity -= 1;
			this.context.updateCart(d);
			this.forceUpdate();
		}
	}

	handleCheckoutClick(){
		
		if(this.props.afterFinish)
			this.props.afterFinish();
	}
	
	render() {

		let cartList = getlsItem('cartData') ? JSON.parse(getlsItem('cartData')) : [];
		let total = 0;

		return (
			<div>

							<table className="table table-borderless table-cart" data-addclass-on-smdown="table-sm">
								<thead>
									<tr className="d-none d-sm-table-row">
										<th colspan="2">Item</th>
										<th className="text-center">Price</th>
										<th className="text-center" width="500" >Qty</th>
										<th className="text-right">Subtotal</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{cartList.map((item, i) => {
										total += parseFloat(item.quantity * item.salesrate);
										return (
											<tr>
												<td className="cart-img nostretch">
													<Link to={'product/' + item.id} ><img className="cart-image" src={Api.ImageUrl + '/' + item.image} alt={item.itemname} /></Link>
												</td>
												<td className="cart-title">
													<Link to={'product/' + item.id} className="h6 bold d-inline-block" title={item.itemname}>{item.itemname}</Link>
													<div className="d-sm-none"><i className="lni-tag"></i> <span className="roboto-condensed bold">Rs. {item.salesrate}</span></div>
													<div><i className="lni-check-mark-cirlce"></i> In Stock</div>
												</td>
												<td className="cart-price text-center">
													<div className="price">
														<span className="text-success">Rs. {item.salesrate}</span>
													</div>
												</td>
												<td className="cart-qty nostretch text-center">
													 <div className="custom-spinner custom-spinner-vertical ml-auto">
													  <button className="btn btn-icon btn-sm rounded-circle btn-text-secondary text-muted shadow-none up" type="button" onClick={this.onMinusQuantity.bind(this, item.id)} ><i className="material-icons">arrow_drop_up</i></button>
													  <input type="number" className="form-control form-control-sm bg-primary-faded text-primary my-1" value={item.quantity}  min="1" max="999" />
													  <button className="btn btn-icon btn-sm rounded-circle btn-text-secondary text-muted shadow-none down" type="button" onClick={this.onAddQuantity.bind(this, item.id)} ><i className="material-icons">arrow_drop_down</i></button>
													</div>
													
													

												</td>
												<td className="cart-subtotal text-right">
													<div className="price">
														<span>Rs. {item.quantity * item.salesrate}</span>
													</div>
												</td>
												<td className="cart-action nostretch pr-0">
													<div className="dropdown">
														<a href="#" className="nav-icon text-secondary dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															<i className="lni-more-alt"></i>
														</a>
														<div className="dropdown-menu dropdown-menu-right">
															{/*<button className="dropdown-item" type="button"><i className="lni-heart"></i> Move to wishlist</button>*/}
															<button className="dropdown-item text-danger" type="button" onClick={this.onRemoveItem.bind(this, item.id)}><i className="lni-cross-circle" ></i> Remove item</button>
														</div>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="text-right">
								<div className="btn-group btn-group-sm">
									<button data-toggle="tooltip" title="Refresh cart" className="btn btn-light text-secondary rounded-pill mr-1"><i className="lni-reload"></i> Refresh</button>
									<button data-toggle="tooltip" title="Remove all items from cart" className="btn btn-light text-secondary rounded-pill" onClick={this.onRemoveAll.bind(this)}><i className="lni-trash"></i> Remove all</button>
								</div>
							</div>
							<div className="row justify-content-between mt-5">
								<div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-2"></div>
								<div className="col-12 col-md-6 col-lg-5 col-xl-4">
									<div className="d-flex">
										<div className="counter">Order Total</div>
										<div className="ml-auto roboto-condensed bold text-primary h4 mb-0">Rs. {parseFloat(total).toFixed(2)}</div>
									</div>
									<hr />
								</div>
								<div className="col-12 text-right">
									<div className="btn-group" role="group">
										<Link to="/home" className="d-none d-sm-inline-block btn rounded-pill mr-1 btn-outline-primary">Continue Shopping</Link>
										<button className={cartList.length == 0 ? "btn rounded-pill btn-primary disabled" : "btn rounded-pill btn-primary"} onClick={this.handleCheckoutClick.bind(this)} >PROCEED TO CHECKOUT</button>
									</div>
								</div>
							</div>
						

			</div>
		);
	}

}
export default Cart;
