import React, { useState, useEffect, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Card, Carousel, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import API from '../../../utils/API';
import { Api } from '../../../utils/Variables';
import SizeChart from '../../../assets/img/size_chart.jpg';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const SingleProductView=(props)=>{
	
	const context = useContext(PsContext);
	const { slug, id } = props.match.params;
	
	const [loader, setLoader] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [sizeChart, setSizeChart] = useState(false);
	const [product, setProduct] = useState([]);
	const [features, setFeatures] = useState([]);
	const [images, setImages] = useState([]);
	const [qty, setQty] = useState(1);
	const [photoIndex, setPhotoIndex] = useState(0);
	
	const [offer, setOffer] = useState([]);
	const [lImage, setLImage] = useState([]);
	
	useEffect(()=>{
		loadProduct();
		loadProductFeatures();
		loadProductImages();
		bodyToTop();
	}, [id]);
	
	const bodyToTop=()=>{
		$('html, body').animate({ scrollTop: 0 }, 'slow');
	};
		
	const loadProduct=()=>{
		
		 API.get('v1/site/item/product/' + id).then(res => {
			if (res['data'].status == '1') {
				//salesdiscount
				//salesrate
				var p = res['data'].data[0];
				p.offer_id = null;
				p.offer_group = null;
				p.offer_value = null;
				p.offer_type = null;
				p.offer_name= null;
				if(res['data'].offer.length>0){
					var off = res['data'].offer[0];
					var amt_minus = (parseFloat(p.salesrate) * parseFloat(off.value))/100;
					var srate = parseFloat(p.salesrate) - parseFloat(amt_minus);
					p.salesdiscount = parseFloat(p.salesrate).toFixed(2);
					p.salesrate = parseFloat(srate).toFixed(2);
					p.offer_id = off.id;
					p.offer_group = off.offer_group;
					p.offer_value = off.value;
					p.offer_name= off.name;
					p.offer_type = 'percentage';
					setOffer(off);
				}
				setProduct(p);
			}
			else{
				setLoader(false);
			}
		  });
		  
	};
	
	const loadProductFeatures=()=>{
		setLoader(true);
		API.get('v1/site/item/features/' + id).then(res => {
			if (res['data'].status == '1') {
				setFeatures(res['data'].data);
				setLoader(false);
			}
			else {
			  setLoader(false);
			}
		  });
	}
	
	const loadProductImages=()=>{
		setLoader(true);
		API.get('v1/site/item/images/' + id).then(res => {
			if (res['data'].status == '1') {

				let s = [];
				res['data'].data.map((item,i)=>{
					s.push(Api.ImageUrl + item.image);
				});
				setImages(res['data'].data);
				setLImage(s);
				setLoader(false);
			}
			else {
			  setLoader(false);
			}
		  });
	}
	
	const addToCart=()=>{
		var dat = product;
		dat['quantity'] = qty;
		context.addToCart(dat);
		toast.success('Successfully added to cart!.');
	}
	
	if(!loader)
	{
		return(
		<>
			 <div className="breadcrumb-container">
				<div className="container-fluid">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item">
						<Link to="/">Home</Link>
					  </li>
					  <li className="breadcrumb-item active" aria-current="page">
						<a alt={product.itemname} >{product.itemname}</a>
					  </li>
					</ol>
				  </nav>
				</div>
			  </div>
			  
			<div className="container-fluid" >
				
						<Row>
							<Col md={6} >
								
								<Card className="card-style1" >
									<Card.Body>
											<div className="swiper-container" id="product-slider">
											<div className="swiper-wrapper">
											
											{images && images.length<1 && (<img 
												className="product_laze_placeholder"  
												alt={product.itemname} 
											/>)}
											
											<Carousel>
												{images && images.length>0 && (
												images.map((item, i) => {
													return (
													<Carousel.Item>
														<img 
															style={{ cursor: 'pointer', minHeight: '400px' }} 
															src={item.image != '' ? Api.ImageUrl + item.image : ''}
															className="d-block swiper-slide product_laze_placeholder"  
															alt={item.itemname} 
															onClick={e => setIsOpen(true)}
														/>
													</Carousel.Item>);
												})
												)}
											</Carousel>
											</div>
										</div>
										</Card.Body>			
									</Card>
							</Col>
							
							<Col md={6} >
								<Card className="card-style1 sm-mt-2" >
									<Card.Body>
										<h5 className='product-title'><b>{product.itemname}</b></h5>
										
										{offer && offer.id &&(<span className="badge badge-warning off-badge" style={{fontSize: '15px', marginBottom: '5px'}} >{offer.name} OFF</span>)}
										<h3 className="price product-price">
											{product.salesdiscount > 0 ? <span className="del">Rs. {product.salesdiscount}</span> : ''}
											<span>Rs. {qty * product.salesrate}</span>
											
										</h3>
										{product && product.showsizechart && product.showsizechart=='1' && (<a 
												href="javascript:;" 
												style={{fontSize: '14px'}} 
												onClick={()=> setSizeChart(true) }
											>
											(See size chart)									
											</a>)}
										</Card.Body>			
									</Card>

									<Card className="card-style1 sm-mt-2" >
										<Card.Body>
											{features && features.length>0 && (<div><hr />
												{features.map((item1, i) =>
													<div className="form-group">
														<label className="bold d-block"><b>{item1.field}</b></label>
														<span>{item1.value}</span>
													</div>)
												}
											<hr /></div>)}
										</Card.Body>			
									</Card>
								  

								  {product && parseFloat(product.stockinhand)>0 && (
									<div className='product-add-to-cart'>
								  <div className="form-row">
									<div className="form-group col-6 col-sm-4 col-md-5 col-lg-4">
									  <label className="d-block font-weight-bold">Quantity</label>
									  <div className="custom-spinner">
										<button className="btn btn-icon rounded-circle btn-faded-primary down" type="button" 
											onClick={()=>{ 
												if(qty>1) setQty(qty-1);
											}}
										>
											<i className="material-icons">remove</i>
										</button>
										<input type="number" className="form-control" value={qty} min="1" max="999" />
										<button className="btn btn-icon rounded-circle btn-faded-primary up" type="button" onClick={()=>{ setQty(qty+1) }} >
											<i className="material-icons">add</i>
										</button>
									  </div>
									</div>
									<div className="form-group col-6 col-sm-8 col-md-7 col-lg-8 d-flex align-items-end">
										<button className="btn rounded-pill btn-theme btn-block atc-demo" type="button" onClick={()=> addToCart() } >
											Add to Cart
										</button>
									</div>
								  </div>
								  </div>)}
								  
								  {product && parseFloat(product.stockinhand)<1 && (<div className="mt-15" >
									<Alert variant="danger" size="sm" > 
										No Stock available
									</Alert>
								  </div>)}
								  
								  {product && product.about &&(<div className="mt-15" >
										<hr />
											<h5>Description</h5>
										<hr />
									  {product.about}
								  </div>)}
								 
							</Col>
							
						</Row>
				
				  
				  
			</div>
			
			
			<Modal show={sizeChart} size="lg" onHide={()=> setSizeChart(false) } >
				<Modal.Header closeButton >
					Size Chart
				</Modal.Header>
				<Modal.Body>
					<img src={SizeChart} style={{ width: '100%' }} />
				</Modal.Body>
			</Modal>

			{isOpen && (
				<Lightbox
					mainSrc={lImage[photoIndex]}
					nextSrc={lImage[(photoIndex + 1) % lImage.length]}
					prevSrc={lImage[(photoIndex + lImage.length - 1) % lImage.length]}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() =>
					setPhotoIndex((photoIndex + lImage.length - 1) % lImage.length)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((photoIndex + 1) % lImage.length)
					}
				/>
				)}
				
		</>
		);
	}
	else{
		return(<Row>
			<Col md={12} style={{paddingTop: '100px' }} >
				<center>
					<div className="ps_round_loader"></div>
				</center>
			</Col>
		</Row>);
	}
}

export default withRouter(SingleProductView);