import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import API from '../../../utils/API';
import { Api } from '../../../utils/Variables';
import { momentDate } from '../../../utils';

const styles = {
	spinnerStyle: {
		position: 'absolute',
		bottom: '4px',
		right: '13px',
	},
	staSpan:{
		float: 'right',
		textTransform: 'uppercase',
	},
};

const MyOrders = (props) =>{
	
	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(true);
	const [repayLoader, setRepayLoader] = useState(false);
	
	const [orderUid, setOrderUid] = useState(props.match.params.uid);
	
	const [orderData, setOrderData] = useState([]);
	
	const [reqData, setReqData] = useState(null);
	const [accessCode, setAccessCode] = useState(null);
	
	const loadOrderData =()=> {
		setLoading(true);
		//API.get(`v1/site/user/orders/${context.state.userData.id}`).then(res=>{
		API.get(`v1/site/user/orders/${context.state.userData.id}`).then(res=>{
			if(res['data'].status=='1'){
				setOrderData(res['data'].data);
			}
			setLoading(false);
		});
	};
		
	const orderStatus=()=>{
		if( orderData && orderData.length>0 ){
			return <span className="text-danger">{orderData[0].orderstatus}</span>;
		}
	};
	
	useEffect(()=>{
		
		loadOrderData();
	},[]);
	
	
	const repayClick=()=>{
		setRepayLoader(true);
		API.get(`v1/site/user/orders/${context.state.userData.id}`).then(res=>{
			if(res['data'].status=='1'){
			
				setReqData(res['data'].reqdata);
				setAccessCode(res['data'].access_code);
				document.getElementById("frmCcPay").submit();
			}
			else{
				toast.error(res['data'].message,{
					 style: {
					  marginTop: '5%',
					  borderRadius: '1px',
					  background: '#333',
					  color: '#fff',
					},
				});
				setRepayLoader(false);
			}
		});
	};
	
	
	const productList=()=>{
		var data = orderData;
		return <Row>
			<Col md={12} >
				<table className="table table-sm mt-3" >
					<thead className="bg-light" >
						<tr>
							<th>S.No</th>
							<th>Date</th>
							<th>Order Id</th>
							<th>Amount</th>
							<th>Status</th>
							<th>#</th>
						</tr>
					</thead>
					<tbody>
					{data.map((item,i)=>{
						return <tr key={i} >
							<td>{i+1}</td>
							<td>{momentDate(item.orderdate,'DD/MM/YYYY')}</td>
							<td>{item.orderno}</td>
							<td align="right" >{item.totalprice}</td>
							<td>{item.orderstatus}</td>
							<td>
							<Link to={`/user/order-status/${item.orderid}`} className="btn btn-primary btn-xs" >
								<i className="icofont-eye"></i> View
							</Link>
							</td>
						</tr>;
					})
					}
					</tbody>
				</table>
			</Col>
			<Col md={12} className="text-right" >
				{data && data.length>0 && data[0].orderstatus=='Payment pending' && (<Button 
					variant="dark" 
					disabled={repayLoader}
					onClick={()=> repayClick() }
				>
					{repayLoader ? <>
						<Spinner
						  as="span"
						  animation="border"
						  size="sm"
						  role="status"
						  aria-hidden="true"
						/>
						&nbsp; Please wait..	
					</> : 'Repay Now'}
				</Button>)}
			</Col>
		</Row>
	};
	
	return(
	<>
	<div className="breadcrumb-container">
	<div className="container-fluid">
	  <nav aria-label="breadcrumb">
		<ol className="breadcrumb">
		  <li className="breadcrumb-item">
			<Link to="/">Home</Link>
		  </li>
		  <li className="breadcrumb-item active" aria-current="page">
			<a>My Orders</a>
		  </li>
		</ol>
	  </nav>
	</div>
  </div>
  
	<div className="container" >
		<Row className="mt-3" >
			<Col md={{span: 10, offset: 1}} >
				<Card>
					<Card.Body>
						<h5 className="card-title">My Orders
							
						</h5>
						<hr />
						<div className="card-text">
						
						{loading && (<center>
							<div className="ps_round_loader"></div>
						</center>)}
						
						{!loading && orderData.length>0 && (productList())}
						
						</div>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</div>
	
	{/*<form action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" method="post" name="redirect" id="frmCcPay" >
		<input type="hidden" name="encRequest" value={reqData} />
		<input type="hidden" name="access_code" value={accessCode} />
	</form>*/}
	
	</>
	);
};
export default withRouter(MyOrders);