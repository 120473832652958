import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';


class Cart extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	render(){
		
		
		return(
			<div>
				
				<div  className="media">
					<a href="shop-single.html"><img src="../img/products/1_small.jpg" width="50" height="50" alt="Hanes Hooded Sweatshirt" /></a>
					<div  className="media-body">
					  <a href="shop-single.html" title="Hanes Hooded Sweatshirt">Hanes Hooded Sweatshirt</a>
					  <span  className="qty">1</span> x <span  className="price">$18.56</span>
					  <button type="button"  className="close" aria-label="Close"><i data-feather="x-circle"></i></button>
					</div>
              </div>
				
			</div>
		);
	}
	
}
export default Cart;