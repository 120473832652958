import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import LOGO_300 from '../../../assets/img/logo_300.png';
import API from '../../../utils/API';

import PsContext from '../../../context/PsContext';

class Footer extends Component {
	
	static contextType = PsContext;
	
	constructor(props) {
		super(props);
		this.state = {
			Company : [],
		}
	}
	
	componentDidMount(){
		
		//this.loadCompany();
	}
	
	loadCompany() {
		try {

			API.get('site/').then(res => {
				if (res['data'].status == '1') {
					this.setState({ Company: res['data'].data, showLoader: false });
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
		}
	}

	render() {

		let LogoImage = LOGO_300;
		var d = this.context.state.company;
		
		return (
			<div>

				<div className="footer">
					<div className="container">
						<div className="row no-gutters">
							<div className="col-sm-6 col-lg-5 px-3">
								<Link className="" to="/" >
								  <img src={LogoImage} alt="Logo" style={{ width: '200px' }} />
								</Link>
								<p>
									{d ? d.companyname : null}<br />
									{d ? d.address1 : null} {d ? d.address2 : null}<br />
									{d ? d.mobile : null}<br />
									{d ? d.email : null}
								</p>
							</div>
							
							<div className="col-6 col-lg-3">
								<h6 className="bold">Quick Links</h6>
								<div className="list-group list-group-flush list-group-no-border list-group-sm">
									<Link to="/about" className="list-group-item list-group-item-action">About Us</Link>
									{/*<a href="javascript:void(0)" className="list-group-item list-group-item-action">Terms and Conditions</a>*/}
									<Link to="/privacy-policy" className="list-group-item list-group-item-action">Privacy Policy</Link>
									<Link to="/refund-policy" className="list-group-item list-group-item-action">Refund Policy</Link>
									<Link to="/shipping-policy" className="list-group-item list-group-item-action">Shipping Policy</Link>
									<Link to="/disclaimer" className="list-group-item list-group-item-action">Disclaimer</Link>
								</div>
							</div>
							<div className="col-6 col-lg-1">
								
							</div>
							<div className="col-sm-6 col-lg-3 sm_hide">
								<div className="fb-page"
									data-href="https://www.facebook.com/SHIBANIFASHION/"
									data-tabs="timeline"
									data-width="350"
									data-height="200"
									data-hide-cover="false"
									data-show-facepile="false">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copyright">Copyright © {new Date().getFullYear()} {d ? d.companyname : null}. All right reserved</div>

			</div>
		);
	}

}
export default Footer;