import React, { Memo, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Api } from '../../../utils/Variables';

const CartProduct = (props) => {
	
	const { product } = props;
	
	return(
		<div className="media">
			<Link to={`/${product.slug}/p/${product.id}`} className="mr-2" onClick={props.onCancel} >
				<img src={Api.ImageUrl + '/' + product.image} width="50" height="50" className="lazy_products" style={{width: '50px', minHeight: '50px',height:'50px'}} />
			</Link>
			<div className="media-body">
			
				<Link to={`/${product.slug}/p/${product.id}`} className="d-block text-body" title={product.itemname} onClick={props.onCancel} >
					{product.itemname} 
				</Link>
				{product.offer_id && product.offer_id!=null && (<span className="badge badge-warning off-badge " style={{fontSize: '13px', marginBottom: '5px'}} >{product.offer_name} OFF</span>)}
				<em className="text-muted">{product.quantity} x {product.salesrate}</em>
			</div>
			{/*<button className="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"><i className="material-icons">close</i></button>*/}
	  </div>
	);
}

export default CartProduct;