import React, { useContext, useState } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';

import PsContext from '../../../context/PsContext';
import LoginForm from '../../pages/userAccount/loginForm';

const TopHeader = () => {
	
	const context = useContext(PsContext);
	const [show, showModal] = useState(false);

	return(
		<div className="topbar sm_hide">
			<div className="container-fluid d-flex align-items-center">

			  <nav className="nav mr-1 ">
				<a className="nav-link nav-link-sm has-icon pl-0" href="#"><i className="material-icons mr-1">phone</i> {context.state.company.mobile}</a>
				<a className="nav-link nav-link-sm has-icon d-none d-md-flex " href={`mailto:${context.state.company.email}`}><i className="material-icons mr-1">mail_outline</i> {context.state.company.email}</a>
			  </nav>

			  {/*<nav className="nav nav-circle d-none d-sm-flex">
				<a className="nav-link nav-link-sm nav-icon p-0" href="#"><i className="custom-icon" data-icon="facebook" data-size="17x17"></i></a>
				<a className="nav-link nav-link-sm nav-icon p-0" href="#"><i className="custom-icon" data-icon="twitter" data-size="17x17"></i></a>
				<a className="nav-link nav-link-sm nav-icon p-0" href="#"><i className="custom-icon" data-icon="instagram" data-size="17x17"></i></a>
			  </nav>*/}

			  {/*<div className="btn-group btn-group-toggle btn-group-sm ml-auto mr-1" data-toggle="buttons" hidden>
				<label className="btn btn-text-primary active">
				  <input type="radio" name="lang" checked /> EN
				</label>
				<label className="btn btn-text-primary">
				  <input type="radio" name="lang" /> RU
				</label>
			  </div>*/}

			  <nav className="nav nav-gap-x-1 ml-auto mr-1">
			  {/*<div className="nav-item nav-pills dropdown">
				  <a className="nav-link nav-link-sm dropdown-toggle no-caret" href="#" id="currencyDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
					$ USD
				  </a>
				  <div className="dropdown-menu dropdown-menu-right font-size-sm" aria-labelledby="currencyDropdown">
					<button className="dropdown-item active" type="button">$ USD</button>
					<button className="dropdown-item" type="button">€ EUR</button>
					<button className="dropdown-item" type="button">£ UKP</button>
					<button className="dropdown-item" type="button">¥ JPY</button>
				  </div>
			  </div>*/}
				
				{/*<div className="nav-item nav-pills dropdown">
				  <a className="nav-link nav-link-sm dropdown-toggle no-caret" href="#" id="langDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
					English
				  </a>
				  <div className="dropdown-menu dropdown-menu-right font-size-sm" aria-labelledby="langDropdown">
					<button className="dropdown-item active" type="button">English</button>
					<button className="dropdown-item" type="button">Français</button>
					<button className="dropdown-item" type="button">Deutsch</button>
					<button className="dropdown-item" type="button">Italiano</button>
				  </div>
				</div>*/}
			  </nav>

			  
			  {context.state.userLogged == 'yes' ?
				<a href="javascript:;" className="nav-link nav-link-sm has-icon" onClick={()=> context.userLogout() } >
					<i className="material-icons mr-1">logout</i> Sign Out
				</a>
			  :
				<a href="javascript:;" className="nav-link nav-link-sm has-icon" onClick={()=> showModal(true) } >
					<i className="material-icons mr-1">person</i> Sign in / Sign Up
				</a>
			  }
			  {/*<a href="" className="d-none d-md-flex" > | </a>
			  <Link to="" className="nav-link nav-link-sm has-icon d-none d-md-flex" >
				<i className="material-icons mr-1">person</i> Register
			  </Link>*/}
			  
			  <Modal show={show} onHide={()=> showModal(false) } backdrop="static" >
				<Modal.Header closeButton >
					Sign In / Sign Up
				</Modal.Header>
				<Modal.Body>
					<LoginForm afterFinish={()=> showModal(false) } />
					
					<br /><br />
					
				</Modal.Body>
			  </Modal>

			</div>
		  </div>
	);
}

export default TopHeader;