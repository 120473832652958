import React, { Component } from 'react';
import $ from 'jquery';
import PsContext from './PsContext';

import { setlsItem, setCookie, getlsItem } from '../utils';

class PsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
		userLogged: getlsItem('userLogged') || 'no',
		showCartModal: false,
		cartCount: getlsItem('cartData') ? JSON.parse(getlsItem('cartData')).length : 0,
		cartData: getlsItem('cartData') ? JSON.parse(getlsItem('cartData')) : [],
		company: getlsItem('company') ? JSON.parse(getlsItem('company')) : [],
		userData: getlsItem('userData') ? JSON.parse(getlsItem('userData')) : [],
		loggedUser: getlsItem('loginUser') ? JSON.parse(getlsItem('loginUser')) : [],
		mobileDevice: false,
    };
	
		this.addToCart = this.addToCart.bind(this);
		this.updateCart = this.updateCart.bind(this);
		this.loginUser = this.loginUser.bind(this);
		this.userLogin = this.userLogin.bind(this);
		this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
		this.showCart = this.showCart.bind(this);
		this.userLogout = this.userLogout.bind(this);
		this.emptyCart = this.emptyCart.bind(this);
	}
	
	emptyCart(){
		setlsItem('cartData', []);
		this.setState({ cartData: [] });
	}
	
	loginUser(dat){
		setlsItem('userData', JSON.stringify(dat));
		this.setState({
			userData: dat
		});
	}
	
	userLogin(dat){
		
		setlsItem('userData',JSON.stringify(dat));
		setlsItem('userLogged','yes');
		this.setState({userData: dat, userLogged: 'yes'});
	}
	
	userLogout(){
		
		setlsItem('userData','');
		setlsItem('userLogged','no');
		this.setState({userData: [], userLogged: 'no'});
	}
	
	updateCart(dat){
		var cartList = dat;
		setlsItem('cartData', JSON.stringify(cartList));
		this.setState({
			cartData: cartList,
			cartCount: Object.keys(cartList).length
		});
	}
	
	addToCart(dat){
		var cartList = getlsItem('cartData') ? JSON.parse(getlsItem('cartData')) : [];
		var cartIndex = this.checkCart(cartList,dat);
		if(cartIndex > -1){
			cartList[cartIndex].quantity = cartList[cartIndex].quantity + dat.quantity;
		}else {
			cartList.push(dat);
		}
		setlsItem('cartData', JSON.stringify(cartList));
		this.setState({
			cartData: cartList,
			cartCount: Object.keys(cartList).length,
			showCartModal: true,
		});
	}

	checkCart(cartList,dat){
		var index = cartList.findIndex(function(obj){
			return(obj.id == dat.id);
		});

		return index;
	}
	
	splitLogin(){
		setlsItem('splitLogged','YES');
		this.setState({splitLogged: 'YES'});
	}
	
	splitLogout(){
		setlsItem('splitLogged','NO');
		this.setState({splitLogged: null});
	}
	
	adminLogin(api){
		setlsItem('adminApi',api);
		setlsItem('adminLogged','YES');
		this.setState({adminapi: api, adminLogged: 'YES'});
	}
	
	updateCompanyInfo(dat){
		
		setCookie('site',JSON.stringify(dat));
		setlsItem('company',JSON.stringify(dat));
		this.setState({company: dat});
	}
	
	
	scrollTop()
	{
		$('html, body').animate({scrollTop:0}, 'slow');
	}
	
	showCart(v=false){
		this.setState({showCartModal: v});
	}
 
  render() {
	  
    return (
      <PsContext.Provider value={{
        state: this.state, 
        addToCart: this.addToCart, 
        updateCart: this.updateCart, 
        loginUser: this.loginUser, 
        userLogin: this.userLogin, 
        updateCompanyInfo: this.updateCompanyInfo, 
        showCart: this.showCart, 
        userLogout: this.userLogout, 
        emptyCart: this.emptyCart, 
        scrollTop: this.scrollTop, 
        scrollTop: this.scrollTop, 
        scrollTop: this.scrollTop, 
        scrollTop: this.scrollTop, 
		
      }}
      >
        {this.props.children}
      </PsContext.Provider>
    );
  }
}

export default PsContextProvider;
