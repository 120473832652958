import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Carousel} from 'react-bootstrap';

class SliderItem extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
		  <Carousel.Item>
			<img
			  className="d-block w-100"
			  src={this.props.image}
			  alt={this.props.title ? this.props.title : ''}
			/>
			{this.props.title ? 
			<Carousel.Caption>
			  <h3>{this.props.title}</h3>
				  {this.props.text ?  <p>{this.props.text}</p> : null}
			</Carousel.Caption>
			: null}
		  </Carousel.Item>	
		);
	}
	
}
export default SliderItem;