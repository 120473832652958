import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

import { Api } from '../../../utils/Variables';

const CategoryTypeMenu = (props) => {
	
	const { type, limit, title } = props;
	
	const [categories, setCategories] = useState([]);
	
	/**
	* to load all the categories and sub categories from service
	*/
	const loadCategories = () =>{
		
		axios.get(`${Api.Url}/v1/site/categories/${type}/${limit}`).then(res=>{
			if(res['data'].status=='1'){
				setCategories( res['data'].data );
			}
		});
	}
	
	useEffect(()=>{
		loadCategories();
	},[]);
	
	
	/**
	* to load all the main categories at top level
	* call the singleCategory to load all the child categories 
	**/
	const mainCategories=()=>{
		var rv = [];
		if(categories && categories.length>0){
			categories.map((item,i)=>{
				rv.push(<Link  className="dropdown-item" to={`/category/${item.slug}`} >
				{item.categoryname}
			</Link>);
			});
		}
		return rv;
	};
	

		
	return(
		 <div className="nav-item dropdown dropdown-hover">
          <a className="nav-link dropdown-toggle no-caret" href="#" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			{title}
          </a>
          <div className="dropdown-menu" aria-labelledby="pagesDropdown">
		  {mainCategories()}
          </div>
        </div>
	);
}

export default CategoryTypeMenu;