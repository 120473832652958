import React, { Component } from 'react';
import './lineloader.css';

class LineLoader extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: this.props.loading
		}
	}
	
	
  render() {	  

   if(!this.props.loading)
   {
	   return( '' );
   } 
    return (
		<div className="line-loader-container" >
			<div className="line-loader"></div>
		</div>
    );
  }
  
}
export default LineLoader;


