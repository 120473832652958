import React, { Component } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import API from '../../../utils/API';
import { Api, COOKIE_KEY } from '../../../utils/Variables';
import { getCookieField, capitalizeFirst, makeUrl } from '../../../utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LOGO_300 from '../../../assets/img/logo_300.png';
import Sanitize from '../../../utils/Sanitize';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
	  searchText : '',
      redirect: false
    }
	this.onChangeSearch = this.onChangeSearch.bind(this);
  }

	onChangeSearch(e) {
		this.state.searchText = e.target.value;
		this.setState({ searchText: e.target.value });
	}
  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    this.loadCategoryMenu();
  }

  handleWindowScroll() {
    var header = document.getElementById("top-header");
    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    if (window.pageYOffset > sticky) {
      header.classList.add("fixed-top");
    } else {
      header.classList.remove("fixed-top");
    }
  }

  loadCategoryMenu() {
    try {
      API.get('v1/site/category/menu').then(res => {
        if (res['data'].status == '1') {
          this.setState({ categoryList: res['data'].data });
        }
      });
    }
    catch (error) {

    }
  }



  render() {

    const cookie = new Cookies();
    let LogoImage = LOGO_300;

    let logo = getCookieField('site', 'logo');
    /*if (logo != '' && logo != undefined)
      LogoImage = Api.ImageUrl + logo;*/

    if (this.state.redirect) {
      return <Redirect to='/login' />
    }

    $('html, body').animate({ scrollTop: 0 }, 'slow');

    let cartList = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : [];
    let total = 0;

    let userData = localStorage.getItem('loginUser') ? JSON.parse(localStorage.getItem('loginUser')) : [];

    return (
      <div>
        <ToastContainer autoClose={2000} />
        <div className="topbar">
          <div className="container d-flex">

            <nav className="nav d-none d-md-flex">
              <a className="nav-link pl-0" href="javascript:void(0)"><i className="lni-phone-handset"></i> {getCookieField('site', 'mobile')}</a>
              <a className="nav-link" href="javascript:void(0)"><i className="lni-envelope"></i> {getCookieField('site', 'email')}</a>
            </nav>

            <nav className="nav">
              <a className="nav-link pr-2 pl-0" target="_blank" href="https://www.facebook.com/SHIBANIFASHION/"><i className="lni-facebook"></i></a>
              <a className="nav-link px-2" target="_blank" href="https://twitter.com/shibanifashion?s=08"><i className="lni-twitter"></i></a>
              <a className="nav-link px-2" target="_blank" href="https://instagram.com/shibanifashion?igshid=3vlkbcbb4bvj"><i className="lni-instagram"></i></a>
            </nav>

            <nav className="nav nav-lang ml-auto">
              {/*  <a  className="nav-link active" href="javascript:void(0)">EN</a>
					  <a  className="nav-link pipe">|</a>
					  <a  className="nav-link" href="javascript:void(0)">RU</a>*/}
            </nav>

            <ul className="nav">
              {cookie.get('cb_ecom_user_logged') == 'yes' ?
                <li className="nav-item dropdown dropdown-hover">
                  <a className="nav-link dropdown-toggle pr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="lni-user"></i> Hi, {userData.contactname} <i className="lni-chevron-down"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="media p-2 align-items-center mb-2">
                      <div className="mr-2 size50x50"><img src="https://mimity-fashion56.netlify.com/img/user/user.svg" alt="user" className="img-thumbnail rounded-circle mr-2 size50x50" /></div>
                      <div className="media-body">
                        <strong>{userData.contactname}</strong>
                        <div className="small">{userData.email}</div>
                        {/* <div className="small counter">1113 points</div>*/}
                      </div>
                    </div>
                    <Link to='/cart' className="dropdown-item has-icon"><i className="lni-cart"></i> My Orders</Link>
                    {/*<a href="account-wishlist.html" className="dropdown-item has-icon has-badge"><i className="lni-heart"></i>Wishlist <span className="badge rounded badge-primary">2</span></a>*/}
                    <Link to="/settings" className="dropdown-item has-icon"><i className="lni-cog"></i> Account Setting</Link>
                    <div className="dropdown-divider"></div>
                    <Link to="/home" className="dropdown-item has-icon text-danger" style={{ cursor: 'pointer' }}><i className="lni-enter"></i> Logout</Link>
                  </div>
                </li>
                :
                <li className="nav-item dropdown dropdown-hover">
                  <a className="nav-link dropdown-toggle pr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    My Account 
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/login" className="dropdown-item has-icon">Login</Link>
                    <Link to='/cart' className="dropdown-item has-icon"><i className="shopping-cart"></i>My Orders</Link>
                    {/*<a href="account-wishlist.html" className="dropdown-item has-icon has-badge"><i data-feather="heart"></i>Wishlist</a>*/}
                  </div>
                </li>
              }
            </ul>


          </div>
        </div>

        <div id="wrapper" style={{ height: '0px' }} ></div>

        <header id="top-header" >
          <div className="container">


            <a className="nav-link nav-icon ml-ni nav-toggler mr-3 d-flex d-lg-none" href="#" data-toggle="modal" data-target="#menuModal">
              <i className="lni-menu"></i>
            </a>


            <Link className="nav-link nav-logo" to="/" >
              <img src={LogoImage} alt="Logo" style={{ width: '100%' }} />
            </Link>


            <ul className="nav nav-main ml-auto d-none d-lg-flex">
              <li className="nav-item">
                <NavLink to="/home" className="nav-link" activeClassName="active" >
                  Home
		          	</NavLink>
              </li>
              {/* 
          <li  className="nav-item dropdown dropdown-hover dropdown-mega">
            <a  className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
              Works <i data-feather="chevron-down"></i>
            </a>
            <div  className="dropdown-menu">
              <div  className="row">
                <div  className="col-lg-6">
                  <div  className="list-group list-group-flush list-group-no-border list-group-sm">
                    <a href="javascript:;"  className="list-group-item list-group-item-action"><strong>CLOTHING</strong></a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Polos &amp; Tees</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Casual Shirts</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Jeans</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Casual Trousers</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Formal Shirts</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Formal Trousers</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Suits &amp; Blazers</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Shorts &amp; 3/4ths</a>
                  </div>
                </div>
                <div  className="col-lg-6 border-left">
                  <div  className="list-group list-group-flush list-group-no-border list-group-sm">
                    <a href="javascript:;"  className="list-group-item list-group-item-action"><strong>FOOTWEAR</strong></a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Sports Shoes</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Sneakers</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Casual Shoes</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Loafers</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Formal Shoes</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Boots</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Outdoor &amp; Hiking</a>
                    <a href="javascript:;"  className="list-group-item list-group-item-action">Floaters</a>
                  </div>
                </div>
                
               </div>
            </div>
          </li>*/}

              {/*<li className="nav-item dropdown dropdown-hover">
                <a className="nav-link dropdown-toggle forwardable" data-toggle="dropdown" href="account-profile.html" role="button" aria-haspopup="true" aria-expanded="false">
                  My Account <i data-feather="chevron-down"></i>
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="javascript:;">Login / Register</a>
                  <a className="dropdown-item" href="javascript:;">Profile Page</a>
                  <a className="dropdown-item" href="javascript:;">Orders List</a>
                  <a className="dropdown-item" href="javascript:;">Order Detail</a>
                  <a className="dropdown-item" href="javascript:;">Wishlist</a>
                  <a className="dropdown-item" href="javascript:;">Address</a>
                </div>
              </li>*/}
				
				<li className="nav-item">
					<NavLink to="/category/29/designer-frocks" className="nav-link" activeClassName="active" >
					  Designer Frocks
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to="/category/21/aari-work-blouse-design" className="nav-link" activeClassName="active" >
					  Aari Work Designs
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to="/category/31/tops" className="nav-link" activeClassName="active" >
					  Tops
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to="/category/33/tailoring-materials" className="nav-link" activeClassName="active" >
					  Tailoring Materials
					</NavLink>
				</li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link" activeClassName="active" >
                  Contact
				</NavLink>
              </li>

              <li className="nav-item dropdown dropdown-hover">
                <a className="nav-link dropdown-toggle forwardable" data-toggle="dropdown" href="account-profile.html" role="button" aria-haspopup="true" aria-expanded="false">
                  More <i data-feather="chevron-down"></i>
                </a>
                <div className="dropdown-menu" style={{ minWidth: '200px',maxHeight : '450px',overflowY : 'scroll' }} >
                  {this.state.categoryList.map((item, i) => {
                    var p = '/category/' + item.id + '/' + makeUrl(item.categoryname);
                    //var p = '/category/'+item.id+;
                    return <NavLink to={{
                      pathname: p,
                      propsCategory: 'test'
                    }}
                      className="dropdown-item" activeClassName="active" style={{ textTransform: 'capitalize' }} >
                      {capitalizeFirst(item.categoryname)}
                    </NavLink>;
                  })
                  }
                </div>
              </li>

            </ul>

            <form className="form-inline form-search ml-auto mr-0 mr-sm-1 d-none d-sm-flex">
              <div className="input-group input-group-search">
                <div className="input-group-prepend">
                  <button className="btn btn-light d-flex d-sm-none search-toggle" type="button"><i data-feather="chevron-left"></i></button>
                </div>
                <input type="text" className="form-control border-0 bg-light input-search" onChange={this.onChangeSearch} placeholder="Search..." />
                <div className="input-group-append">
					<Link to={"/search/" + makeUrl(this.state.searchText)} >
						<button className="btn btn-light" type="submit"><i className="lni-search"></i></button>
					</Link>
                </div>
              </div>
            </form>


            <ul className="nav ml-auto ml-sm-0">

              <li className="nav-item d-block d-sm-none ml-2 ml-lg-0"><a className="nav-link nav-icon search-toggle" href="#"><i data-feather="search"></i></a></li>


              <li className="nav-item dropdown dropdown-hover dropdown-cart">
                <a className="nav-link nav-icon mr-nis dropdown-toggle forwardable ml-2" data-toggle="dropdown" href="cart.html" role="button" aria-haspopup="true" aria-expanded="false">
                  <i className="lni-cart"></i>
                  <span className="badge badge-primary">{cartList.length > 0 ? cartList.length : ''}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  {cartList.length > 0 ?
                    <React.Fragment>
                      {cartList.map((item, i) => {
                        total += parseFloat(item.quantity * item.salesrate);
                        return (
                          <div className="media">
                            <Link to={"product/" + item.id}><img src={Api.ImageUrl + '/' + item.image} width="50" height="50" alt={item.itemname} /></Link>
                            <div className="media-body">
                              <Link to={"product/" + item.id} title={item.itemname}>{item.itemname}</Link>
                              <span className="qty">{item.quantity}</span> x <span className="price">Rs. {item.salesrate}</span>
                              <button type="button" className="close" aria-label="Close"><i data-feather="x-circle"></i></button>
                            </div>
                          </div>
                        );
                      })}

                      <div className="d-flex justify-content-between pb-3 pt-2">
                        <span>Total</span>
                        <strong>Rs. {parseFloat(total).toFixed(2)}</strong>
                      </div>
                      <div className="d-flex justify-content-between pb-2">
                        <div className="w-100 mr-1">
                          <Link to="/cart" className="btn btn-block rounded-pill btn-secondary">View Cart</Link>
                        </div>
                        <div className="w-100 ml-1">
                          <Link to="/shipping" className="btn btn-block rounded-pill btn-primary">Checkout</Link>
                        </div>
                      </div>
                    </React.Fragment> :
                    <div className="alert alert-danger mt-4">
                      <h4>No items in your cart.!</h4>
                    </div>
                  }

                </div>
              </li>

            </ul>

          </div>
        </header>


        <div className="modal modal-left modal-menu" id="menuModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header shadow">
                <a className="h5 mb-0 d-flex align-items-center" href="index-2.html">
                  <img src={LOGO_300} alt="" className="mr-3" style={{ width: '100%' }} />
                </a>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body shadow">
                <ul className="menu" id="menu">
                  <li className="no-sub mm-active"><a href="index-2.html"><i data-feather="home"></i> Home</a></li>
                  <li>
                    <a href="#" className="has-arrow"><i data-feather="shopping-bag"></i> Shop</a>
                    <ul>
                      <li><a href="shop-categories.html">Shop Categories</a></li>
                      <li><a href="shop-grid.html">Shop Grid</a></li>
                      <li><a href="shop-list.html">Shop List</a></li>
                      <li><a href="shop-single.html">Single Product</a></li>
                      <li><a href="shop-single2.html">Single Product v2</a></li>
                      <li><a href="cart.html">Cart</a></li>
                      <li><a href="shipping.html">Checkout</a></li>
                      <li>
                        <a href="#">Submenu</a>
                        <ul>
                          <li><a href="javascript:void(0)">item #1</a></li>
                          <li><a href="javascript:void(0)">item #2</a></li>
                          <li><a href="javascript:void(0)">item #3</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" className="has-arrow"><i data-feather="rss"></i> Blog</a>
                    <ul>
                      <li><a href="blog-grid.html">Post Grid</a></li>
                      <li><a href="blog-list.html">Post List</a></li>
                      <li><a href="blog-single.html">Single Post</a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" className="has-arrow"><i data-feather="user"></i> Account</a>
                    <ul>
                      <li><a href="account-login.html">Login / Register</a></li>
                      <li><a href="account-profile.html">Profile Page</a></li>
                      <li><a href="account-orders.html">Orders List</a></li>
                      <li><a href="account-order-detail.html">Order Detail</a></li>
                      <li><a href="account-wishlist.html" className="has-badge">Wishlist <span className="badge rounded badge-primary">2</span></a></li>
                      <li><a href="account-address.html">Address</a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="#" className="has-arrow"><i data-feather="file"></i> Pages</a>
                    <ul>
                      <li><a href="about.html">About Us</a></li>
                      <li><a href="contact.html">Contact Us</a></li>
                      <li><a href="compare.html">Compare</a></li>
                      <li><a href="faq.html">Help / FAQ</a></li>
                      <li><a href="404.html">404 Not Found</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }

}
export default Header;