import React, { Memo, useContext, useEffect } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import $ from 'jquery';

import PsContext from '../../../context/PsContext';
import UserMenu from './userMenu';
import UserMenuNew from './userMenuNew';
import CartLink from './cartLink';
import MobileMenuLink from './mobileMenuLink';
import TopSearchBar from './topSearchBar';

import LOGO from '../../../assets/img/needle_logo.png';

const Header = () => {
	
	const context = useContext(PsContext);
	
	useEffect(() => {
		window.addEventListener('scroll', handleWindowScroll);
	});

	const handleWindowScroll = ()=>{
		
		var header = document.getElementById("top-header1");
		
		if(header && header != null ){
		
			var sticky = header.offsetTop;

			if (window.pageYOffset > sticky) {
			  header.classList.add("fixed-top");
			} else {
			  header.classList.remove("fixed-top");
			}
		}
	}
	
	return(
		 <header id="top-header1" >
			<div className="container ">
				<div className="row d-flex align-items-center" style={{ width: '100%' }} >
					<div className="col-md-3 text-center" >
						 
						<MobileMenuLink />
						
						<Link to="/">
							<img src={LOGO} alt="Logo" className="site_header_logo" />
						</Link>
						
						<span className=" d-md-none" >
							
							<CartLink />
							
							{context.state.userLogged=='yes' && (<UserMenuNew style={{position: 'absolute', top: '13px', right: '20px'}} />)}
							
						</span>
						
					</div>
			
					<div className="col-md-7 d-none d-sm-block" >
						
						<TopSearchBar />
						
					</div>
					
					<div className="col-md-2 d-none d-sm-block " >
						 <div className="btn-group" role="group" aria-label="Basic example">
						 {/*<a className="btn nav-icon has-badge d-none d-sm-flex" href="account-wishlist.html">
							  <i className="material-icons">favorite_border</i>
							  <span className="badge badge-pill badge-danger">3</span>
						 </a>*/}
							
							
						<CartLink />
						
						{/*context.state.userLogged=='yes' && (<UserMenu />)*/}
						{context.state.userLogged=='yes' && (<UserMenuNew />)}
						
							
						</div>
						

					</div>
					
				</div>				
			</div>
		  </header>
	);
}

export default Header;