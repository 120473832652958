import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ContentLoader from 'react-content-loader'

import IMG from '../../../assets/img/product_placeholder.jpg';

const ProductLoader = (props) =>{
	
	const size = props.size || 4;
	
	const loader =()=>{
		const rv = [];
		for(var i=0;i<size;i++){ 
			rv.push(<Col md={3} >
				<Card className="card-product" style={{ marginBottom: '15px' }} >
					<Card.Body style={{padding: '0px' }} >
						<img className="card-img-top lazy_products" />
					</Card.Body>
				</Card>
			</Col>);
		}
		return rv;
	};
	
	return(
		<Row>
			{loader()}
		</Row>
	);
}
export default ProductLoader;