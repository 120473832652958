import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

import { Api } from '../../../utils/Variables';

const CategoriesMenu = () => {
	
	const [categories, setCategories] = useState([]);
	
	/**
	* to load all the categories and sub categories from service
	*/
	const loadCategories = () =>{
		
		axios.get(`${Api.Url}v1/site/categorylist/5`).then(res=>{
			if(res['data'].status=='1'){
				setCategories( res['data'].data );
			}
		});
	}
	
	useEffect(()=>{
		loadCategories();
	},[]);
	
	
	/**
	* to load all the main categories at top level
	* call the singleCategory to load all the child categories 
	**/
	const mainCategories=()=>{
		var rv = [];
		if(categories && categories.length>0){
			categories.map((item,i)=>{
				if(item.subcategory && item.subcategory.length>0)
				{
					rv.push(<Col>
						<div className="list-group list-group-flush list-group-no-border list-group-sm">
						  <Link to={`/category/${item.slug}`} className="list-group-item list-group-item-action">
							<strong>{item.categoryname.toUpperCase()}</strong>
						  </Link>
						  {item.subcategory && item.subcategory.length>0 && (singleCategory(item.subcategory))}
						</div>
					</Col>);
				}
			});
		}
		return rv;
	};
	
	/**
	* single category and sub categories
	**/
	const singleCategory=(items)=>{
		var rv = [];
		items.map((item,i)=>{
			rv.push(<Link to={`/category/${item.slug}`} className="list-group-item list-group-item-action">
				{item.categoryname}
			</Link>);
		});
		return rv;
	};
		
	return(
		 <div className="dropdown-menu" aria-labelledby="megaDropdown">
			<Row>
				{mainCategories()}
			</Row>
		  </div>
	);
}

export default CategoriesMenu;