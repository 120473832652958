import React, { useContext } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import $ from 'jquery';

import CategoriesMenu from './categoriesMenu';
import CategoryTypeMenu from './categoryTypeMenu';

const TopMenu = () => {
		
	return(
		 <header  className="top_header_menu d-none d-sm-block" >
			<div className="container-fluid">
			  <nav className="nav nav-circle d-flex d-lg-none">
				<a href="#menuModal" data-toggle="modal" className="nav-link nav-icon"><i className="material-icons">menu</i></a>
				<div className="" style={{ position: 'absolute', right: '25px'}} >
					<a className="btn nav-icon has-badge " href="account-wishlist.html">
					  <i className="material-icons">shopping_cart</i>
					  <span className="badge badge-pill badge-danger">4</span>
					</a>
				</div>
			  </nav>

			  <nav className="nav nav-main nav-gap-x-1 nav-pills mr-auto ml-auto d-none d-lg-flex">
			   
				<Link className="nav-item nav-link" to="/">
				  HOME
				</Link>
				
				{/*<Link className="nav-item nav-link" href="">
				  NEW ARRIVALS
				</Link>*/}
				
				 <div className="nav-item dropdown dropdown-hover dropdown-mega">
					  <a className="nav-link dropdown-toggle no-caret" href="javascript:;" id="megaDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
						CATEGORIES
					  </a>
					
					<CategoriesMenu />
					
				</div>
				
				<CategoryTypeMenu type="collection" limit="20" title="COLLECTIONS" />
				
				<CategoryTypeMenu type="occasion" limit="20" title="OCCASIONS" />
				
				<Link className="nav-item nav-link" to="/offers">
				  OFFERS <span className="badge badge-success">new</span>
				</Link>
				
			  </nav>

			</div>
		  </header>
	);
}

export default TopMenu;