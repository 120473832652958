import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';

class Collection extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	
	
	render(){
		
		
		return(
			 <a href={this.props.url} >
                <div  className="card zoom-hover">
                  <div data-cover={this.props.image} data-xs-height="100px" data-sm-height="128px" data-md-height="115px" data-lg-height="154px" data-xl-height="192px"></div>
                  <div  className="card-img-overlay card-img-overlay-transparent">
                    <h3  className="card-title text-center">{this.props.title}</h3>
                  </div>
                </div>
              </a>
		);
	}
	
}
export default Collection;