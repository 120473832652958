import React,{ useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { Row, Col, Card } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import { Api } from '../../../utils/Variables';
import { getCookieField } from '../../../utils';

import FeaturedProduct from '../../elements/Products/Featured';
import ProductLoader from '../Home/productLoader';

const Offers=(props)=>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState(true);
	const [data, setData] = useState([]);
	
	
	const loadData=()=>{
		setLoader(true);
		axios.get(Api.Url+'v1/site/offerlist').then(res=>{
			if(res['data'].status=='1'){
				setData(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const bodyToTop=()=>{
		$('html, body').animate({ scrollTop: 0 }, 'slow');
	};
	
	const addToCart=(id)=>{
		var d = data;
		var index = d.findIndex(function (obj) {
			return (obj.id == id);
		})
		var dat = d[index];
		dat['quantity'] = 1;
		context.addToCart(dat);
		toast.success('Successfully added to cart!.');
	};
	
	useEffect(()=>{
		loadData();
		bodyToTop();
	},[]);
	
	const showItems=()=>{
		let col = data.length>3 ? 3 : 6;
		if(data.length==3) col= 4;
		if(data.length==2) col= 6;
		
		//if(data.length==1) return;
		var rv = [];
		data.map((item,i)=>{
/* 			var p = item;
			p['uid'] = Math.random();
			console.log(item);
			let original_rate = item.salesrate;
			var amt_minus = (parseFloat(p.salesrate) * parseFloat(p.value))/100;
			var srate = parseFloat(p.salesrate) - parseFloat(amt_minus);
			
			p.salesdiscount = parseFloat(p.salesrate).toFixed(2);
			p.salesrate = parseFloat(srate).toFixed(2);
			//p.offer_id = p.id;
			p.offer_group = p.offer_group;
			p.offer_value = p.value;
			p.offer_name= p.offername;
			p.offer_type = 'percentage'; */
			var amt_minus = (parseFloat(item.salesrate) * parseFloat(item.value))/100;
			
			rv.push(<Col md={col} >
				<Link to={`/${item.slug}/p/${item.itemid}`}>
				<Card className="mb-3 card-product" >
					<img src={`${Api.ImageUrl}cimage/thumb/${item.id}`}  className="card-img lazy_products " />
					<Card.Body>
						<span className="badge badge-warning">{item.offername} OFF</span>
						 <div className="price " style={{paddingTop: '10px', paddingBottom: '10px'}} >
							{parseFloat(item.value) > 0 ? <span className="del">Rs. {item.salesrate}</span> : ''}
							<span>Rs. {(parseFloat(item.salesrate) - parseFloat(amt_minus)).toFixed(2)}</span>
							
						  </div>
						<div className="text-dark " style={{fontSize: '14px'}} ><b>{item.itemname}</b></div>
					</Card.Body>
					{/*<div className="card-footer">
						<button className="btn btn-sm rounded-pill btn-theme btn-block atc-demo" >
							Add to Cart
						</button>
					</div>*/}
				</Card>
				</Link>
			</Col>);
		});
		return rv;
	};
	
	return(
	<>
		 <div className="breadcrumb-container">
			<div className="container-fluid">
			  <nav aria-label="breadcrumb">
				<ol className="breadcrumb">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item active" aria-current="page">
					<a>Best Offers</a>
				  </li>
				</ol>
			  </nav>
			</div>
		  </div>
		  
		  <div className="container-fluid mb-3">
				
			<Row className="gutters-3" >
				
				<Col md={12} >
					<h4 className="bold text-center mt-gutter mb-3">Best Offers</h4>
				</Col>
				
				{loader && (<Col md={12} style={{paddingTop: '100px' }} >
					<center>
						<div className="ps_round_loader"></div>
					</center>
				</Col>)}
				
				{showItems()}
				
				
			</Row>
			
		</div>
		
	</>
	);
};
export default Offers;