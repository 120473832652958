import React, { useState, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Modal, Collapse } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';
import CategoryTypeMenu from './categoryTypeMenu';
import CategoriesMenu from './categoriesMenu';

import LOGO from '../../../assets/img/needle_logo.png';

const MobileMenuModal = (props) => {
	
	const context = useContext(PsContext);
	const [showId, setShowId] = useState(null);
	
	const handleShowClick=(id)=>{
		if(showId==id){
			setShowId(null);
		}
		else{
			setShowId(id);	
		}
	};
	
	const handleMenuClick=()=>{
		
		props.onCancel();
	}
	
	return(
		<Modal show className="modal-content-left ps_mobile_menu_modal" onHide={props.onCancel} >
			<Modal.Header className="border-bottom" closButton >
					<img src={LOGO} alt="Logo" className="site_header_logo" />
				
				 <button className="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal" onClick={props.onCancel} >
					<i className="material-icons">close</i>
				  </button>
			</Modal.Header>
			<Modal.Body>
				<ul className="menu metismenu" id="menu">
				
					<Link className="nav-item nav-link" to="/" onClick={()=> handleMenuClick() } >
					  HOME
					</Link>
										
					<li>
					<a className="has-arrow" onClick={()=> handleShowClick('category') } >
						CATEGORIES
					</a>
						<Collapse in={showId=='category'}>
						 <ul>
							<CategoriesMenu menuClick={handleMenuClick} />
						  </ul>
					  </Collapse>
				  </li>
				  
				  <li>
					<a className="has-arrow" onClick={()=> handleShowClick('collection') } >
						COLLECTIONS
					</a>
						<Collapse in={showId=='collection'}>
						 <ul>
							<CategoryTypeMenu type="collection" limit="20" title="COLLECTIONS" menuClick={()=> handleMenuClick()} />
						  </ul>
					  </Collapse>
				  </li>
				  
				   <li>
					<a className="has-arrow" onClick={()=> handleShowClick('occasion') } >
						OCCASIONS
					</a>
						<Collapse in={showId=='occasion'}>
						 <ul>
							<CategoryTypeMenu type="occasion" limit="20" title="OCCASIONS" menuClick={()=> handleMenuClick()} />
						  </ul>
					  </Collapse>
				  </li>
					
					
					<Link className="nav-item nav-link" to="/offers" onClick={()=> handleMenuClick() } >
					  OFFERS <span className="badge badge-success">new</span>
					</Link>
					
					<hr />
					{context.state.userLogged == 'yes' ?
					<a href="javascript:;" className="nav-item nav-link nav-link-sm has-icon" onClick={()=> {
						context.userLogout();
						props.afterLogout();
					}} >
						SIGN OUT
					</a>
					:
					<a href="javascript:;" className="nav-item  nav-link nav-link-sm has-icon" onClick={e=>props.afterFinish()}>
						 SIGN IN / SIGN UP
					</a>
					}
				
				  </ul>
			</Modal.Body>
		</Modal>
	);
}

export default MobileMenuModal;