
const COOKIE_KEY = 'cb_ecom';

const Api = {
	/*Url: 'http://localhost/office/shibani/site/php/api/',
	ImageUrl: 'http://localhost/office/shibani/php/'*/

	Url: 'https://shibanifashion.com/site/api/',
	ImageUrl: 'https://shibanifashion.com/billing/services/'
};

export {
	COOKIE_KEY,
	Api
};
