import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Api } from '../../../utils/Variables';

class Featured extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {

		var d = this.props;
		const { item } = this.props;
		return (
			<div className="card card-product mb-3" >
				<div className="card-body " style={{padding: '0px' }} >
				{/*<button className="wishlist atw-demo active" title="Added to wishlist">
						<i className="lni-heart"></i>
				</button>*/}
				
					<Link to={`/${item.slug}/p/${d.id}`} >
						<img className="card-img-top lazy_products" src={Api.ImageUrl + 'cimage/thumb/' + d.id} alt={d.title} style={{ width: '100%' }} alt={item.itemname} />
					</Link>
					<div className="card-title" style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
						<Link  to={`/${item.slug}/p/${d.id}`} className=" text-dark" style={{fontSize : '15px'}}><b>{d.title}</b></Link>
							{/*<span className="badge badge-success">New arrival</span>*/}
					</div>
					<div className="price"><span className="h5">Rs. {d.amount}</span></div>
				</div>
				<div className="card-footer">
					{item && item.stockinhand && parseFloat(item.stockinhand)>0 && (<button className="btn btn-sm rounded-pill btn-theme btn-block atc-demo" onClick={this.props.addToCart}>
							Add to Cart
					</button>)}
					
					{item && item.stockinhand && parseFloat(item.stockinhand)<1 && (<Link className="btn btn-sm rounded-pill btn-theme btn-block atc-demo" to={`/${item.slug}/p/${d.id}`} >
							Add to Cart
					</Link>)}
					
				</div>
			</div>
		);
	}

}
export default Featured;