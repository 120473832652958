import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import API from '../../../utils/API';

const styles = {
	spinnerStyle: {
		position: 'absolute',
		bottom: '4px',
		right: '13px',
	}
};

const PaymentOptions = (props) =>{
	
	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [paymentType, setPaymentType] = useState('online');
	
	const [reqData, setReqData] = useState(null);
	const [accessCode, setAccessCode] = useState(null);
	
	const handleSubmit=(e)=>{
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoading(true);
		API.post('v1/site/payment', $("#frm_paymentConfirm").serialize()).then(res => {
			if (res['data'].status == '1') {
				/* toast.success('Address Updated');		
				context.userLogin(res['data'].data);
				if(props.afterFinish)
					props.afterFinish();
				setLoading(false); */
				setReqData(res['data'].reqdata);
				setAccessCode(res['data'].access_code);
				document.getElementById("frmCcPay").submit();
				context.emptyCart();
			}
			else {
				toast.error(res['data'].message,{
					 style: {
					  marginTop: '5%',
					  borderRadius: '1px',
					  background: '#333',
					  color: '#fff',
					},
				});
				setLoading(false); 
			}
		});
	};
	
	const total = context.state.cartData.reduce(function(tot, ar){
		return tot += parseFloat(ar.quantity * ar.salesrate)
	},0);
	
	return(
	<>
	<Form
		action=""
		method="post"
		id="frm_paymentConfirm"
		noValidate 
		validated={validated} 
		onSubmit={handleSubmit}
	>
		<input type="hidden" name="user_id" value={context.state.userData.id} />
		<input type="hidden" name="total_amount" value={total} />
		<input type="hidden" name="payment_type" value={paymentType} />
		<input type="hidden" name="cart_data" value={JSON.stringify(context.state.cartData)} />
		<input type="hidden" name="user_data" value={JSON.stringify(context.state.userData)} />
		
		<Row style={{ borderBottom: '1px dashed grey', paddingBottom: '30px' }} >
			<Col>
				<label>Total Payable</label>
			</Col>
			<Col>
				<div className="text-right">
					<b>Rs. {total}</b>
				</div>
			</Col>
		</Row>
		
		<Row className={paymentType=='online' ? "mt-3 bg-light py-3" : "mt-3"} >
			<Col md={12} >
				<label>
					<input type="radio" name="payment_type" value="online" checked={paymentType=='online'} /> Online Payment
				</label>
				<div style={{ paddingLeft: '20px', marginTop: '10px' }} >
					<Button
						type="submit"
						size="md"
						variant="dark"
						disabled={loading}
					>
					{loading ? <>
						<Spinner
						  as="span"
						  animation="border"
						  size="sm"
						  role="status"
						  aria-hidden="true"
						/>
						&nbsp; Please wait..					
					</> : <>Pay {total}</>}
					</Button>
				</div>
			</Col>
		</Row>
		
	</Form>
	
	
	<form action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" method="post" name="redirect" id="frmCcPay" >
		<input type="hidden" name="encRequest" value={reqData} />
		<input type="hidden" name="access_code" value={accessCode} />
	</form>
	
	</>
	);
};
export default PaymentOptions;