import Header from './Header';
import Footer from './Footer';
import Cart from './Cart';
import Slider from './Slider';
import CategoryWidget from './Widgets/categorywidget';
import FeaturedProduct from './Products/Featured';
import ProductCollection from './Products/Collection';
import RingLoader from './Loaders/ringloader';
import LineLoader from './Loaders/lineloader';

export {
	Header,
	Footer,
	Slider,
	Cart,
	CategoryWidget,
	FeaturedProduct,
	ProductCollection,
	RingLoader,
	LineLoader
}