import React, { useState, useContext, useEffect } from 'react';
import { Redirect, Link, NavLink, withRouter } from 'react-router-dom';
import $ from 'jquery';

import PsContext from '../../../context/PsContext';

const TopSearchBar = (props) => {
	
	const context = useContext(PsContext);
	const [redirect, setRedirect] = useState(false);
	const [keyVal, setKeyVal] = useState(null);
		
	const btnClick = (e) =>{
		e.preventDefault();
		var val = document.getElementById("inp_top_search_keyword").value;
		setKeyVal(val);
		setRedirect(true);		
	}
	
	useEffect(()=>{
		checkUrl();	
	},[]);
	
	useEffect(()=>{
		
		if(redirect){
			props.history.push(`/search/${keyVal}`);
			setRedirect(false);
		}
		
	},[redirect]);
	
	const checkUrl=()=>{
		var path = props.location.pathname;
		var pathArray = path.split('/');
		if(pathArray.length>1 && pathArray[1]=='search'){
			document.getElementById("inp_top_search_keyword").value = pathArray[2];
		}
		else{
			document.getElementById("inp_top_search_keyword").value = '';
		}
	}
	
	return(		
		<form id="frm_topSearch" onSubmit={btnClick} >
		<div className="input-group input-group-">
		  <input type="text" className="form-control" placeholder="Search for products, brand, collections and more.." style={{ borderRadius: '0px'}} id="inp_top_search_keyword" />
		   <div className="input-group-prepend" style={{ padding: 0 }} >
				<span className="input-group-text" style={{ padding: 0, borderRadius: 0 }} >
					<button className="btn btn-sm" style={{ borderRadius: '0px'}} type="submit" >
						<i className="material-icons">search</i>
					</button>
				</span>
			</div>
		</div>	
		</form>
	);
	
}

export default withRouter(TopSearchBar);