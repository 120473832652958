import React, { Memo, useContext, useEffect } from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
import $ from 'jquery';

import PsContext from '../../../context/PsContext';

import LOGO from '../../../assets/img/logo_300.png';

const Footer = () => {
	
	const context = useContext(PsContext);
	
	const d = context.state.company;
	
	const topCategories = [
		{text: 'Festival', to: '/category/festival'},
		{text: 'Holy-Communion', to: '/category/holy-communion'},
		{text: 'Wedding Collections', to: '/category/wedding'},
		{text: 'Receptions', to: '/category/receptions'},
		{text: 'Bridal', to: '/category/bridal'},
	];
		
	return(
		
    <footer className="footer">
        
        <div className="footer-middle">
            <div className="container-fluid">
                <div className="bottom-inner">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            
                            <div className="single-footer f-contact">
                                <h3>Get In Touch With Us</h3>
                                <p className="phone">Phone: {d ? d.mobile : null}</p>
                                <ul>
                                    <li><span>Monday-Saturday: </span> 9.30 am - 8.00 pm</li>
									{/* <li><span>Saturday: </span> 09.30 am - 8.00 pm</li>*/}
                                </ul>
                                <p className="mail">
                                    <a href={`mailto:${d ? d.email : null}`}>{d ? d.email : null}</a>
                                </p>
                            </div>
                            
                        </div>
                        <div className="col-lg-2 col-md-6 col-6">
                            
                            <div className="single-footer f-link">
                                <h3>Information</h3>
                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/refund-policy">Refund Policy</Link></li>
                                    <li><Link to="/shipping-policy">Shipping Policy</Link></li>
                                    <li><Link to="/disclaimer">Disclaimer</Link></li>
                                </ul>
                            </div>
                            
                        </div>
                        <div className="col-lg-2 col-md-6 col-6">
                            
                            <div className="single-footer f-link">
                                <h3>Top Categories</h3>
                                <ul>
								{topCategories.map((item) => <li><Link to={item.to} >{item.text}</Link></li>)}
                                </ul>
                            </div>
                            
                        </div>
                        <div className="col-lg-5 col-md-6 col-12 sm_hide">
                            
                            <div className="single-footer our-app">
                                <div className="fb-page"
									data-href="https://www.facebook.com/SHIBANIFASHION/"
									data-tabs="timeline"
									data-width="550"
									data-height="260"
									data-hide-cover="false"
									data-show-facepile="false">
								</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="footer-bottom">
            <div className="container-fluid">
                <div className="inner-content">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-12">
                            <div className="copyrights">
                                <p>Designed and Developed by<a href="https://peacesoft.in" title="Peace Soft Technology" target="_blank" > Peace Soft</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                        </div>
                        <div className="col-lg-4 col-12 text-right footer_ps_links">
                            <ul className="socila">
                                <li><a href="https://www.facebook.com/SHIBANIFASHION/" target="_blank" ><i className="lni lni-facebook-filled"></i></a></li>
                                <li><a href="javascript:void(0)"><i className="lni lni-twitter-original"></i></a></li>
                                <li><a href="https://www.instagram.com/shibanifashion/" target="_blank" ><i className="lni lni-instagram"></i></a></li>
                                <li><a href="javascript:void(0)"><i className="lni lni-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </footer>
	);
}

export default Footer;